var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_c('CIcon',{attrs:{"name":"cilUser"}}),_vm._v(" Người dùng ")],1),_c('CButton',{staticClass:"float-right",attrs:{"color":"success","size":"sm"},on:{"click":function (e) {
            _vm.userId = 0;
            _vm.editing = true;
          }}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Thêm người dùng ")],1)],1),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"4"}},[_c('CSelect',{attrs:{"label":"Cửa hàng","placeholder":"Chọn Cửa hàng","value":_vm.agencyId,"options":[
              { label: 'Tất cả', value: null } ].concat( _vm.agencies.map(function (_) {
                return {
                  label: _.Name,
                  value: _.Id,
                };
              }) ),"horizontal":""},on:{"update:value":function($event){_vm.agencyId=$event}}})],1),_c('CCol',{attrs:{"md":"4"}},[_c('CInput',{attrs:{"label":"Tên","placeholder":"Tên","horizontal":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doFilter()}},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}})],1),_c('CCol',{staticClass:"text-center",attrs:{"md":"4"}},[_c('CButton',{staticClass:"mr-2 mb-3",attrs:{"color":"primary"},on:{"click":_vm.doFilter}},[_c('CIcon',{attrs:{"name":"cil-search","custom-classes":"c-icon m-0"}}),_vm._v(" Tìm kiếm ")],1),_c('CButton',{staticClass:"mb-3",attrs:{"color":"secondary"},on:{"click":_vm.clear}},[_c('CIcon',{attrs:{"name":"cil-x-circle","custom-classes":"c-icon m-0"}}),_vm._v(" Đặt lại ")],1)],1)],1),_c('odata-table',{ref:"lstUser",attrs:{"url":"odata/User","filter":_vm.filter,"select":"Id,Name,Email,Mobile,Role,Status,LastLogin,AgencyIds","sortBy":"CreatedAt desc","pageSize":_vm.pageSize,"colSetting":{
          Action: {
            display: '#',
            sortable: false,
            style: 'min-width: 50px;',
          },
          Name: {
            field: 'Name',
            display: 'Tên',
            sortable: true,
            style: 'min-width: 100px',
          },
          Email: {
            field: 'Email',
            display: 'Tên đăng nhập',
            sortable: true,
            style: 'min-width: 120px',
          },
          Mobile: {
            field: 'Mobile',
            display: 'Điện thoại',
            sortable: true,
            style: 'min-width: 100px',
          },
          Role: {
            field: 'Role',
            display: 'Vai trò',
            sortable: true,
            style: 'min-width: 100px',
          },
          Status: {
            field: 'Status',
            display: 'Trạng thái',
            sortable: true,
            style: 'min-width: 100px',
          },
          LastLogin: {
            field: 'LastLogin',
            display: 'Đăng nhập',
            sortable: true,
            style: 'min-width: 100px',
          },
          AgencyIds: {
            field: 'AgencyIds',
            display: 'Cửa hàng',
            sortable: false,
            style: 'min-width: 120px',
          },
        }},scopedSlots:_vm._u([{key:"tbody",fn:function(ref){
        var rows = ref.rows;
return _vm._l((rows),function(row,i){return _c('tr',{key:("tr-" + i)},[_c('td',[_vm._v(_vm._s(i + 1 + (_vm.$refs.lstUser.page - 1) * _vm.pageSize)+".")]),_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:","title":("Cập nhật: " + (row.Name))},on:{"click":function (e) {
                    _vm.userId = row.Id;
                    _vm.editing = true;
                  }}},[_c('CIcon',{attrs:{"name":"cil-pencil"}}),_vm._v(" "+_vm._s(row.Name)+" ")],1)]),_c('td',[_vm._v(_vm._s(row.Email))]),_c('td',[_vm._v(_vm._s(row.Mobile))]),_c('td',[_vm._v(_vm._s(row.Role))]),_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(row.Status)}},[_vm._v(" "+_vm._s(_vm.$const.STATUS_TEXT[row.Status])+" ")])],1),_c('td',[_vm._v(" "+_vm._s(row.LastLogin ? _vm.$moment .utc(row.LastLogin) .local() .format("DD/MM/YY HH:mm") : "")+" ")]),_c('td',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(row.Role != _vm.$const.ROLES.StockAdmin ? _vm.getAgencyNames(row.AgencyIds) : "")+" ")])])})}}])})],1)],1),_c('detail',{attrs:{"editing":_vm.editing,"userId":_vm.userId,"agencyId":_vm.agencyId,"agencies":_vm.agencies},on:{"update:editing":function($event){_vm.editing=$event},"update:userId":function($event){_vm.userId=$event},"update:user-id":function($event){_vm.userId=$event},"reload":function($event){return _vm.$refs.lstUser.loadData()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }