<template>
  <CModal
    :title="user.Id ? 'Cập nhật Người dùng' : 'Thêm Người dùng'"
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    centered
    size="lg"
  >
    <template v-slot:body-wrapper>
      <div class="modal-body" style="max-height: calc(100vh - 11.6rem);">
        <CRow>
          <CCol md="4">
            <CInput
              label="Họ Tên*"
              placeholder="Họ Tên"
              v-model="user.Name"
              :add-input-classes="{
                'is-invalid': !!inValidObject['user.Name'],
              }"
              :invalid-feedback="inValidObject['user.Name']"
            />
          </CCol>
          <CCol md="5">
            <CInput
              label="Tên người dùng*"
              placeholder="Tên người dùng"
              v-model="user.Email"
              :add-input-classes="{
                'is-invalid': !!inValidObject['user.Email'],
              }"
              :invalid-feedback="inValidObject['user.Email']"
              :disabled="!!user.Id"
            />
          </CCol>
          <CCol md="3">
            <CInput
              type="password"
              label="Mật khẩu*"
              placeholder="Mật khẩu"
              v-model="user.Password"
              :add-input-classes="{
                'is-invalid': !!inValidObject['user.Password'],
              }"
              :invalid-feedback="inValidObject['user.Password']"
            />
          </CCol>
          <CCol md="4">
            <CInput
              label="Số điện thoại*"
              placeholder="Số điện thoại"
              v-model="user.Mobile"
              :add-input-classes="{
                'is-invalid': !!inValidObject['user.Mobile'],
              }"
              :invalid-feedback="inValidObject['user.Mobile']"
            />
          </CCol>
          <CCol md="4">
            <CSelect
              label="Trạng thái*"
              placeholder="Chọn Trạng thái"
              :value.sync="user.Status"
              :options="
                Object.keys($const.STATUS_TEXT).map((_) => {
                  return {
                    value: _,
                    label: $const.STATUS_TEXT[_],
                  };
                })
              "
              :add-input-classes="{
                'is-invalid': !!inValidObject['user.Status'],
              }"
              :invalid-feedback="inValidObject['user.Status']"
            />
          </CCol>
          <CCol md="4">
            <CSelect
              label="Vai trò*"
              placeholder="Chọn Vai trò"
              :value.sync="user.Role"
              :options="
                Object.keys($const.ROLES).map((_) => {
                  return {
                    value: $const.ROLES[_],
                    label: $const.ROLES[_],
                  };
                })
              "
              :add-input-classes="{
                'is-invalid': !!inValidObject['user.Role'],
              }"
              :invalid-feedback="inValidObject['user.Role']"
            />
          </CCol>
          <CCol md="12" v-if="user.Role != $const.ROLES.SuperAdmin">
            <div class="form-group" v-if="user.Role == $const.ROLES.StockAdmin">
              <label>Kho</label>
              <v-select
                multiple
                v-model="warehouseId_edit"
                :options="[
                  { label: 'Tất cả', value: 'all' },
                  ...warehouses.map((_) => {
                    return {
                      label: _.Name,
                      value: _.Id.toString(),
                    };
                  }),
                ]"
                :reduce="(p) => p.value"
                label="label"
                placeholder="Chọn Kho"
                :class="{
                  'is-invalid border-danger rounded': !!inValidObject[
                    'warehouseId_edit'
                  ],
                }"
                @input="optionInputWarehouse"
              />
              <div class="invalid-feedback">
                {{ inValidObject["warehouseId_edit"] }}
              </div>
            </div>

            <div class="form-group" v-else>
              <label>Cửa hàng</label>
              <v-select
                multiple
                v-model="agencyId_edit"
                :options="[
                  { label: 'Tất cả', value: 'all' },
                  ...agencies.map((_) => {
                    return {
                      label: _.Name,
                      value: _.Id.toString(),
                    };
                  }),
                ]"
                :reduce="(p) => p.value"
                label="label"
                placeholder="Chọn Cửa hàng"
                :class="{
                  'is-invalid border-danger rounded': !!inValidObject[
                    'agencyId_edit'
                  ],
                }"
                @input="optionInputAgency"
              />
              <div class="invalid-feedback">
                {{ inValidObject["agencyId_edit"] }}
              </div>
            </div>

            <!-- <CSelect
              label="Cửa hàng"
              placeholder="Chọn Cửa hàng"
              :value.sync="agencyId_edit"
              :options="[
                { label: 'Tất cả', value: null },
                ...agencies.map((_) => {
                  return {
                    label: _.Name,
                    value: _.Id,
                  };
                }),
              ]"
              :add-input-classes="{
                'is-invalid': !!inValidObject['agencyId_edit'],
              }"
              :invalid-feedback="inValidObject['agencyId_edit']"
            /> -->
          </CCol>
        </CRow>
      </div>
    </template>
    <template v-slot:footer>
      <CButton class="btn btn-primary" @click="saveUser" :disabled="saving">
        <CIcon name="cil-save" />
        Lưu thông tin
      </CButton>
      <div class="d-inline-block font-italic" v-if="saving">
        <CSpinner color="info" size="sm" class="ml-2" />
        Đang xử lý...
      </div>
    </template>
  </CModal>
</template>
<script>
export default {
  props: ["editing", "userId", "agencyId", "agencies"],
  data() {
    return {
      detailModal: false,
      inValidObject: {},
      user: this.newUser(),
      saving: false,
      agencyId_edit: null,
      warehouseId_edit: null,
      warehouses: [],
    };
  },
  async mounted() {
    if (
      this.user &&
      this.user.Role == this.$const.ROLES.StockAdmin &&
      (!this.warehouses || !this.warehouses.length)
    ) {
      this.warehouses = await this.loadWarehouse();
    }
  },
  watch: {
    async userId(val) {
      await this.loadUserInfo(val);
    },
    editing(val) {
      this.detailModal = val;
      if (!val) {
        this.$emit("update:userId", null);
      }
    },
    detailModal(val) {
      this.$emit("update:editing", val);
    },
    agencyId(val) {
      this.agencyId_edit =
        this.user.Role != this.$const.ROLES.SuperAdmin && val
          ? [val.toString()]
          : null;
    },
    async "user.Role"(val) {
      if (val == this.$const.ROLES.SuperAdmin) {
        this.agencyId_edit = null;
        this.warehouseId_edit = null;
      } else if (
        val == this.$const.ROLES.StockAdmin &&
        (!this.warehouses || !this.warehouses.length)
      ) {
        this.warehouses = await this.loadWarehouse();
      }
    },
  },
  methods: {
    newUser() {
      return {
        Id: 0,
        Name: null,
        Email: null,
        Password: "klc",
        Mobile: null,
        Role: this.$const.ROLES.Agent,
        AgencyIds: this.agencyId_edit,
        Status: this.$const.STATUS.Active.toString(),
      };
    },

    async loadUserInfo(userId) {
      try {
        this.inValidObject = {};
        if (userId) {
          let filters = [];
          userId && filters.push(`Id eq ${userId}`);
          let resp = await this.$http.get(`odata/User`, {
            params: {
              $top: 1,
              $filter: filters.join(" and "),
              $select: "Id,Name,Email,Mobile,Role,Status,AgencyIds",
            },
          });
          if (resp && resp.status == 200) {
            let _ = resp.data.value[0];
            this.user = {
              Id: _.Id,
              Name: _.Name,
              Email: _.Email,
              Mobile: _.Mobile,
              Role: _.Role,
              Status: _.Status.toString(),
            };

            if (_.AgencyIds) {
              if (_.Role == this.$const.ROLES.StockAdmin) {
                this.agencyId_edit = [];
                this.warehouseId_edit = _.AgencyIds.includes(",")
                  ? _.AgencyIds.split(",")
                  : [_.AgencyIds];
              } else {
                this.agencyId_edit = _.AgencyIds.includes(",")
                  ? _.AgencyIds.split(",")
                  : [_.AgencyIds];
                this.warehouseId_edit = [];
              }
            } else {
              this.agencyId_edit = [];
              this.warehouseId_edit = [];
            }
          }
        } else {
          this.user = this.newUser();
        }
      } catch (error) {
        alert(error);
      }
    },

    async saveUser() {
      let user = this.user;
      this.inValidObject = this.checkValid(user);
      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }

      this.saving = true;
      try {
        let userData = {
          Name: user.Name,
          Mobile: user.Mobile,
          Status: parseInt(user.Status),
          Role: user.Role,
          AgencyIds:
            user.Role == this.$const.ROLES.StockAdmin
              ? this.warehouseId_edit && this.warehouseId_edit.length
                ? this.warehouseId_edit.join(",")
                : null
              : this.agencyId_edit && this.agencyId_edit.length
              ? this.agencyId_edit.join(",")
              : null,
        };
        // User
        if (!user.Id) {
          // post
          userData.Email = user.Email;
          userData.Password = user.Password;

          let resp = await this.$http.post(`odata/User`, userData);
          if (resp && resp.status == 201) {
            this.detailModal = false;
            this.$emit("reload");
          }
        } else {
          // patch
          if (user.Password) {
            userData.Password = user.Password;
          }
          let resp = await this.$http.patch(`odata/User/${user.Id}`, userData);
          if (resp && resp.status == 204) {
            this.detailModal = false;
            this.$emit("reload");
          }
        }
      } catch (error) {
        alert(error);
      }
      this.saving = false;
    },

    checkValid(user) {
      let inValidObject = {};

      if (!user.Name) inValidObject["user.Name"] = "Vui lòng nhập Tên!";
      if (!user.Email)
        inValidObject["user.Email"] = "Vui lòng nhập Tên người dùng!";
      if (!user.Password && !user.Id)
        inValidObject["user.Password"] = "Vui lòng nhập Mật khẩu!";
      if (!user.Mobile)
        inValidObject["user.Mobile"] = "Vui lòng nhập Số điện thoại!";
      if (!user.Status)
        inValidObject["user.Status"] = "Vui lòng chọn Trạng thái!";
      if (!user.Role) inValidObject["user.Role"] = "Vui lòng chọn Vai trò!";

      if (user.Role != this.$const.ROLES.SuperAdmin) {
        if (user.Role == this.$const.ROLES.StockAdmin) {
          if (!this.warehouseId_edit || !this.warehouseId_edit.length) {
            inValidObject["warehouseId_edit"] = "Vui lòng chọn Kho!";
          }
        } else {
          if (!this.agencyId_edit || !this.agencyId_edit.length) {
            inValidObject["agencyId_edit"] = "Vui lòng chọn Cửa hàng!";
          }
        }
      }

      return inValidObject;
    },

    optionInputAgency(option) {
      if (option && option.length && option[option.length - 1] == "all") {
        this.agencyId_edit = ["all"];
      } else {
        this.agencyId_edit = this.agencyId_edit.filter((_) => _ != "all");
      }
    },

    optionInputWarehouse(option) {
      if (option && option.length && option[option.length - 1] == "all") {
        this.warehouseId_edit = ["all"];
      } else {
        this.warehouseId_edit = this.warehouseId_edit.filter((_) => _ != "all");
      }
    },

    async loadWarehouse() {
      let warehouses = [];
      let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/InventoryWarehouse", {
        params: {
          $top: 1000,
          $filter: `Status eq ${active}`,
          $select: "Id,Name",
          isAll: true,
        },
      });
      if (resp && resp.status == 200) {
        warehouses = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
          };
        });
      }
      return warehouses;
    },
  },
};
</script>
