<template>
  <div>
    <CCard>
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cilUser" />
          Người dùng
        </span>
        <CButton
          color="success"
          size="sm"
          class="float-right"
          @click="
            (e) => {
              userId = 0;
              editing = true;
            }
          "
        >
          <CIcon name="cil-plus" />
          Thêm người dùng
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="4">
            <CSelect
              label="Cửa hàng"
              placeholder="Chọn Cửa hàng"
              :value.sync="agencyId"
              :options="[
                { label: 'Tất cả', value: null },
                ...agencies.map((_) => {
                  return {
                    label: _.Name,
                    value: _.Id,
                  };
                }),
              ]"
              horizontal
            />
          </CCol>
          <CCol md="4">
            <CInput
              label="Tên"
              placeholder="Tên"
              v-model="fullName"
              @keyup.enter="doFilter()"
              horizontal
            />
          </CCol>
          <CCol md="4" class="text-center">
            <CButton color="primary" class="mr-2 mb-3" @click="doFilter">
              <CIcon name="cil-search" custom-classes="c-icon m-0" />
              Tìm kiếm
            </CButton>

            <CButton color="secondary" class="mb-3" @click="clear">
              <CIcon name="cil-x-circle" custom-classes="c-icon m-0" />
              Đặt lại
            </CButton>
          </CCol>
        </CRow>
        <odata-table
          ref="lstUser"
          url="odata/User"
          :filter="filter"
          select="Id,Name,Email,Mobile,Role,Status,LastLogin,AgencyIds"
          sortBy="CreatedAt desc"
          :pageSize="pageSize"
          :colSetting="{
            Action: {
              display: '#',
              sortable: false,
              style: 'min-width: 50px;',
            },
            Name: {
              field: 'Name',
              display: 'Tên',
              sortable: true,
              style: 'min-width: 100px',
            },
            Email: {
              field: 'Email',
              display: 'Tên đăng nhập',
              sortable: true,
              style: 'min-width: 120px',
            },
            Mobile: {
              field: 'Mobile',
              display: 'Điện thoại',
              sortable: true,
              style: 'min-width: 100px',
            },
            Role: {
              field: 'Role',
              display: 'Vai trò',
              sortable: true,
              style: 'min-width: 100px',
            },
            Status: {
              field: 'Status',
              display: 'Trạng thái',
              sortable: true,
              style: 'min-width: 100px',
            },
            LastLogin: {
              field: 'LastLogin',
              display: 'Đăng nhập',
              sortable: true,
              style: 'min-width: 100px',
            },
            AgencyIds: {
              field: 'AgencyIds',
              display: 'Cửa hàng',
              sortable: false,
              style: 'min-width: 120px',
            },
          }"
        >
          <template v-slot:tbody="{ rows }">
            <tr v-for="(row, i) in rows" :key="`tr-${i}`">
              <td>{{ i + 1 + ($refs.lstUser.page - 1) * pageSize }}.</td>
              <td>
                <a
                  href="javascript:"
                  class="text-primary"
                  :title="`Cập nhật: ${row.Name}`"
                  @click="
                    (e) => {
                      userId = row.Id;
                      editing = true;
                    }
                  "
                >
                  <CIcon name="cil-pencil" />
                  {{ row.Name }}
                </a>
              </td>
              <td>{{ row.Email }}</td>
              <td>{{ row.Mobile }}</td>
              <td>{{ row.Role }}</td>
              <td>
                <CBadge :color="getBadge(row.Status)">
                  {{ $const.STATUS_TEXT[row.Status] }}
                </CBadge>
              </td>
              <td>
                {{
                  row.LastLogin
                    ? $moment
                        .utc(row.LastLogin)
                        .local()
                        .format("DD/MM/YY HH:mm")
                    : ""
                }}
              </td>
              <td class="text-truncate">
                {{
                  row.Role != $const.ROLES.StockAdmin
                    ? getAgencyNames(row.AgencyIds)
                    : ""
                }}
              </td>
            </tr>
          </template>
        </odata-table>
      </CCardBody>
    </CCard>
    <detail
      :editing.sync="editing"
      :userId.sync="userId"
      :agencyId="agencyId"
      :agencies="agencies"
      @reload="$refs.lstUser.loadData()"
    />
  </div>
</template>

<script>
import odataTable from "@/components/odataTable.vue";
import detail from "./Detail.vue";

export default {
  name: "User",
  components: {
    odataTable,
    detail,
  },
  data() {
    return {
      editing: false,
      userId: 0,
      pageSize: 10,
      agencyId: null,
      fullName: null,
      agencies: [],
      filters: null,
    };
  },
  computed: {
    filter() {
      let filters = [];

      if (this.filters && this.filters.length) {
        filters = [...this.filters];
      }

      return filters.join(" and ");
    },

    // filter() {
    //   let filters = [];

    //   this.agencyId &&
    //     filters.push(`Role ne '${this.$const.ROLES.StockAdmin}'`);
    //   this.agencyId &&
    //     filters.push(
    //       `(startswith(AgencyIds, '${this.agencyId},') or endswith(AgencyIds, ',${this.agencyId}') or contains(AgencyIds, ',${this.agencyId},') or AgencyIds eq '${this.agencyId}')`
    //     );

    //   return filters.join(" and ");
    // },
  },
  async mounted() {
    this.agencies = await this.loadAgencies();
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case this.$const.STATUS.Active:
          return "success";
        case this.$const.STATUS.InActive:
          return "secondary";
        default:
          "primary";
      }
    },

    async loadAgencies() {
      let agencies = [];
      let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/Agency", {
        params: {
          $top: 1000,
          $filter: `Status eq ${active}`,
          $select: "Id,Name",
          $orderby: `Name asc`,
          isAll: true,
        },
      });
      if (resp && resp.status == 200) {
        agencies = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
          };
        });
      }
      return agencies;
    },

    getAgencyNames(agencyIds) {
      //debugger;
      let result = "";
      if (agencyIds) {
        if (agencyIds == "all") {
          result = "Tất cả cửa hàng";
        } else {
          result = (agencyIds.includes(",")
            ? agencyIds.split(",")
            : [agencyIds]
          )
            .map((_) => {
              let id = parseInt(_);
              let agency = this.agencies.find((a) => a.Id == id);
              if (agency) {
                return agency.Name;
              } else {
                return null;
              }
            })
            .filter((_) => _)
            .join(", ");
        }
      }
      return result;
    },

    doFilter() {
      let filters = [];
      this.agencyId &&
        filters.push(`Role ne '${this.$const.ROLES.StockAdmin}'`);
      this.agencyId &&
        filters.push(
          `(startswith(AgencyIds, '${this.agencyId},') or endswith(AgencyIds, ',${this.agencyId}') or contains(AgencyIds, ',${this.agencyId},') or AgencyIds eq '${this.agencyId}')`
        );
      this.fullName &&
        filters.push(
          `(contains(Name,'${this.fullName}') or contains(Email, '${this.fullName}'))`
        );
      filters.join(" and ");

      if (JSON.stringify(this.filters) != JSON.stringify(filters)) {
        this.filters = filters;
      } else {
        this.$refs.lstUser.loadData();
      }
    },
    clear() {
      this.agencyId = null;
      this.fullName = null;
    },
  },
};
</script>
