var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":_vm.user.Id ? 'Cập nhật Người dùng' : 'Thêm Người dùng',"show":_vm.detailModal,"closeOnBackdrop":false,"centered":"","size":"lg"},on:{"update:show":function($event){_vm.detailModal=$event}},scopedSlots:_vm._u([{key:"body-wrapper",fn:function(){return [_c('div',{staticClass:"modal-body",staticStyle:{"max-height":"calc(100vh - 11.6rem)"}},[_c('CRow',[_c('CCol',{attrs:{"md":"4"}},[_c('CInput',{attrs:{"label":"Họ Tên*","placeholder":"Họ Tên","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['user.Name'],
            },"invalid-feedback":_vm.inValidObject['user.Name']},model:{value:(_vm.user.Name),callback:function ($$v) {_vm.$set(_vm.user, "Name", $$v)},expression:"user.Name"}})],1),_c('CCol',{attrs:{"md":"5"}},[_c('CInput',{attrs:{"label":"Tên người dùng*","placeholder":"Tên người dùng","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['user.Email'],
            },"invalid-feedback":_vm.inValidObject['user.Email'],"disabled":!!_vm.user.Id},model:{value:(_vm.user.Email),callback:function ($$v) {_vm.$set(_vm.user, "Email", $$v)},expression:"user.Email"}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CInput',{attrs:{"type":"password","label":"Mật khẩu*","placeholder":"Mật khẩu","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['user.Password'],
            },"invalid-feedback":_vm.inValidObject['user.Password']},model:{value:(_vm.user.Password),callback:function ($$v) {_vm.$set(_vm.user, "Password", $$v)},expression:"user.Password"}})],1),_c('CCol',{attrs:{"md":"4"}},[_c('CInput',{attrs:{"label":"Số điện thoại*","placeholder":"Số điện thoại","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['user.Mobile'],
            },"invalid-feedback":_vm.inValidObject['user.Mobile']},model:{value:(_vm.user.Mobile),callback:function ($$v) {_vm.$set(_vm.user, "Mobile", $$v)},expression:"user.Mobile"}})],1),_c('CCol',{attrs:{"md":"4"}},[_c('CSelect',{attrs:{"label":"Trạng thái*","placeholder":"Chọn Trạng thái","value":_vm.user.Status,"options":Object.keys(_vm.$const.STATUS_TEXT).map(function (_) {
                return {
                  value: _,
                  label: _vm.$const.STATUS_TEXT[_],
                };
              }),"add-input-classes":{
              'is-invalid': !!_vm.inValidObject['user.Status'],
            },"invalid-feedback":_vm.inValidObject['user.Status']},on:{"update:value":function($event){return _vm.$set(_vm.user, "Status", $event)}}})],1),_c('CCol',{attrs:{"md":"4"}},[_c('CSelect',{attrs:{"label":"Vai trò*","placeholder":"Chọn Vai trò","value":_vm.user.Role,"options":Object.keys(_vm.$const.ROLES).map(function (_) {
                return {
                  value: _vm.$const.ROLES[_],
                  label: _vm.$const.ROLES[_],
                };
              }),"add-input-classes":{
              'is-invalid': !!_vm.inValidObject['user.Role'],
            },"invalid-feedback":_vm.inValidObject['user.Role']},on:{"update:value":function($event){return _vm.$set(_vm.user, "Role", $event)}}})],1),(_vm.user.Role != _vm.$const.ROLES.SuperAdmin)?_c('CCol',{attrs:{"md":"12"}},[(_vm.user.Role == _vm.$const.ROLES.StockAdmin)?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Kho")]),_c('v-select',{class:{
                'is-invalid border-danger rounded': !!_vm.inValidObject[
                  'warehouseId_edit'
                ],
              },attrs:{"multiple":"","options":[
                { label: 'Tất cả', value: 'all' } ].concat( _vm.warehouses.map(function (_) {
                  return {
                    label: _.Name,
                    value: _.Id.toString(),
                  };
                }) ),"reduce":function (p) { return p.value; },"label":"label","placeholder":"Chọn Kho"},on:{"input":_vm.optionInputWarehouse},model:{value:(_vm.warehouseId_edit),callback:function ($$v) {_vm.warehouseId_edit=$$v},expression:"warehouseId_edit"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["warehouseId_edit"])+" ")])],1):_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Cửa hàng")]),_c('v-select',{class:{
                'is-invalid border-danger rounded': !!_vm.inValidObject[
                  'agencyId_edit'
                ],
              },attrs:{"multiple":"","options":[
                { label: 'Tất cả', value: 'all' } ].concat( _vm.agencies.map(function (_) {
                  return {
                    label: _.Name,
                    value: _.Id.toString(),
                  };
                }) ),"reduce":function (p) { return p.value; },"label":"label","placeholder":"Chọn Cửa hàng"},on:{"input":_vm.optionInputAgency},model:{value:(_vm.agencyId_edit),callback:function ($$v) {_vm.agencyId_edit=$$v},expression:"agencyId_edit"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["agencyId_edit"])+" ")])],1)]):_vm._e()],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.saving},on:{"click":_vm.saveUser}},[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v(" Lưu thông tin ")],1),(_vm.saving)?_c('div',{staticClass:"d-inline-block font-italic"},[_c('CSpinner',{staticClass:"ml-2",attrs:{"color":"info","size":"sm"}}),_vm._v(" Đang xử lý... ")],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }